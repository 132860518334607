import React from "react";
import "./BtnMail.scss";

const BtnMail = () => {
  return (
    <a href="mailto:hola@quiken.mx">
      <button className="btn-white">Hola@quiken.mx</button>
    </a>
  );
};

export default BtnMail;
