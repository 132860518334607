import styled from "styled-components";

const MidScreenWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 670px;
  box-sizing: border-box;
  align-content: center;
`;

export default MidScreenWrapper;
